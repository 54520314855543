import React from 'react'

function Footer() {
  return (
    <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="mb-5">
                <h3 className="footer-heading mb-4">About NeuraLyrics</h3>
                <p>
                  NeuraLyrics is a free webapp that offer the possibility
                  to generate a new world of ideas for musicians for lyric
                  ideas through artificial intelligence.
                  This is possible thanks to Bidirectional LSTM Neural Networks model
                  generated by a database with around 28,000+ song lyrics from different Pop genre artists.
                  This service was developed by National Polytechnic Institute students.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-5 mb-lg-0">
              <div className="row mb-5">
                <div className="col-md-12">
                  <h3 className="footer-heading mb-4">Navigations</h3>
                </div>
                <div className="col-md-6 col-lg-6">
                  <ul className="list-unstyled">
                    <li><a href="/">Home</a></li>
                    <li><a href="/samples">Samples</a></li>
                    <li><a href="/team">Team</a></li>
                    <li><a href="/developers">Developers</a></li>
                  </ul>
                </div>
                <div className="col-md-6 col-lg-6">
                  <ul className="list-unstyled">
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                    <li><a href="/faq">FAQ</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-5 mb-lg-0">
              <div className="mb-5">
                <h3 className="footer-heading mb-4">More about NeuraLyrics</h3>
                <div className="block-16">
                  <figure>
                    <img src="images/img_1.jpg" alt="Placeholder" className="img-fluid rounded" />
                    <a href="https://vimeo.com/650435165" className="play-button popup-vimeo"><span className="icon-play" /></a>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5 mt-5 text-center">
            <div className="col-md-12">
              <p>
                Copyright ©2021 All rights reserved | Made with <i className="icon-heart text-danger" aria-hidden="true" /> by <a href="https://youtu.be/awC7XcnI1Cg" target="_blank" rel="noreferrer">Team 2020-B002</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer
